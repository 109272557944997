import React from "react";
import {
    Route,
    Routes,
    useNavigate
} from "react-router-dom";
import {Helmet} from "react-helmet";
import data from './lang.json';
import PortfolioItem from "./PortfolioItem";

const App = () => {

    const navigate = useNavigate();

    function openPortfolioItem(id) {
        navigate("/item/" + id);
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content={data.description}/>
                <meta name="keywords" content={data.keywords}/>
                <meta name="author" content={data.author}/>
                <title>{data.title}</title>
                <meta property="og:title" content={data.title}/>
                <meta property="og:description" content={data.description}/>
                <meta property="og:image" content="https://www.mucadoo.dev/media/site/thumb.png"/>
                <meta property="og:url" content="https://www.mucadoo.dev"/>
            </Helmet>
            <div id="rm-container" className="rm-container rm-closed">
                <header id="header">
                    <nav>
                        <ul>
                            <li><a href='#/home'></a></li>
                            <li><a href='#/resume'></a></li>
                            <li><a href='#/portfolio'></a></li>
                            <li><a href='#/contact'></a></li>
                        </ul>
                    </nav>
                </header>
                <div className="rm-wrapper">
                    <div className="rm-cover">
                        <div id="home" className="rm-front page">
                            <div className="antiscroll-wrap">
                                <div className="antiscroll-inner">
                                    <div className="cover">
                                        <div className="cover-image-holder">
                                            <img src="/media/site/cover.jpg" alt="cover-image"/>
                                        </div>
                                        <h1>{data.nameCover}</h1>
                                        <h2>{data.job}</h2>
                                        {data.cover.map((object, i) =>
                                            <h3><span>{object.title}</span>{object.text}</h3>
                                        )}
                                        <a href="#/resume" className="rm-button-open ribbon">
                                            <span className="ribbon-stitches-top"></span>
                                            <strong className="ribbon-content">{data.open}</strong>
                                            <span className="ribbon-stitches-bottom"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="resume" className="rm-back page">
                            <div className="antiscroll-wrap">
                                <div className="antiscroll-inner">
                                    <div className="content">
                                        <h2><span>{data.resume}</span></h2>
                                        <h4 className="about-text">{data.resumeText1}
                                            <span className="label">{data.resumeTextBold}</span>
                                        {data.resumeText2}</h4>
                                        <h3><span>{data.employment}</span></h3>
                                        <div className="history-group">
                                            {data.works.map((object, i) =>
                                                <div className="history-unit">
                                                    <h4 className="work-time">{object.time}</h4>
                                                    <div className="work-desc">
                                                        <h4>{object.job}</h4>
                                                        <h5>{object.company}</h5>
                                                        <p>{object.desc}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <h3><span>{data.education}</span></h3>
                                        <div className="history-group">
                                            {data.edu.map((object, i) =>
                                                <div className="history-unit">
                                                    <h4 className="work-time">{object.time}</h4>
                                                    <div className="work-desc">
                                                        <h4>{object.course}</h4>
                                                        <h5>{object.school}</h5>
                                                        <p>{object.desc}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="launch">
                                            <a href={data.cvLink} target="_blank"
                                               className="btn">{data.downCv}</a>
                                        </div>
                                        <h3><span>{data.devSkill}</span></h3>
                                        <div className="skill-group">
                                            {data.devSkills.map((object, i) =>
                                                <div className="skill-unit">
                                                    <h4>{object.name}</h4>
                                                    <div className="bar" data-percent={object.value}>
                                                        <div className="progress"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <h3><span>{data.desSkill}</span></h3>
                                        <div className="skill-group">
                                            {data.designSkills.map((object, i) =>
                                                <div className="skill-unit">
                                                    <h4>{object.name}</h4>
                                                    <div className="bar" data-percent={object.value}>
                                                        <div className="progress"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <h3><span>{data.langSkill}</span></h3>
                                        <div className="skill-group">
                                            {data.langSkills.map((object, i) =>
                                                <div className="skill-unit">
                                                    <h4>{object.name}</h4>
                                                    <div className="bar" data-percent={object.value}>
                                                        <div className="progress"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rm-overlay"></div>
                        </div>
                    </div>
                    <div id="portfolio" className="rm-middle portfolio page">
                        <div className="rm-inner">
                            <div className="antiscroll-wrap">
                                <div className="antiscroll-inner">
                                    <div className="content">
                                        <h2><span>{data.portfolio}</span></h2>
                                        <ul id="filters">
                                            <li className="current"><a href="#" data-filter="*">{data.all}</a></li>
                                            <li><a href="#" data-filter=".job">{data.jobs}</a></li>
                                            <li><a href="#" data-filter=".api">{data.apis}</a></li>
                                            <li><a href="#" data-filter=".desktop">{data.desktops}</a></li>
                                            <li><a href="#" data-filter=".site">{data.sites}</a></li>
                                            <li><a href="#" data-filter=".collage">{data.collages}</a></li>
                                        </ul>
                                        <div className="portfolio-items">
                                            {data.portfolios.map((object, i) =>
                                                <div className={object.class}>
                                                    <div className="media-box">
                                                        <img src={object.img} alt="portfolio-post"/>
                                                        <div className="mask">
                                                            {object.type == 'hentry' &&
                                                                <a className="link"
                                                                   onClick={() => openPortfolioItem(object.href)}></a>}
                                                            {object.type == 'img' &&
                                                                <a href={object.href} title={object.titleIn}
                                                                   data-lightbox-gallery="fancybox-item-01"
                                                                   className="lightbox"></a>}
                                                            {object.type == 'multi-img' &&
                                                                object.items.map((object, i) =>
                                                                    <a href={object.href} title={object.title}
                                                                       data-lightbox-gallery="fancybox-item-02"
                                                                       className={i > 0 ? 'lightbox hidden' : 'lightbox'}></a>
                                                                )
                                                            }
                                                            {object.type == 'url' &&
                                                                <a href={object.href} target="_blank"></a>}
                                                        </div>
                                                    </div>
                                                    <h4>{object.titleOut}</h4>
                                                    <p className="category">{object.ctg}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rm-overlay"></div>
                        </div>
                    </div>
                    <div className="rm-right">
                        <div className="rm-front">
                            <div className="cover">
                                <div className="cover-image-holder">
                                    <img src="/media/site/cover-2.jpg" alt="cover-image"/>
                                </div>
                            </div>
                        </div>
                        <div id="contact" className="rm-back page">
                            <div className="antiscroll-wrap">
                                <div className="antiscroll-inner">
                                    <div className="content">
                                        <h2><span>{data.contact}</span></h2>
                                        <p className="center">{data.feelFree}</p>
                                        <h3><span>{data.social}</span></h3>
                                        <ul className="social">
                                            {data.socials.map((object, i) =>
                                                <li><a className={object.class} href={object.link}
                                                       target="_blank"></a></li>
                                            )}
                                        </ul>
                                        <h3><span>{data.call}<i className="icon-arrow-down"></i></span></h3>
                                        <div className="contact-form">
                                            <div className="letter cf">
                                                <div className="stamp">
                                                    <img src="/media/site/stamp-image.png" alt="stamp"/>
                                                </div>
                                                <div className="letter-info">
                                                    <h4>{data.nameContact}</h4>
                                                    <p><a href={'mailto:' + data.email}><i>{data.email}</i></a></p>
                                                    <p> - {data.planet}</p>
                                                </div>
                                            </div>
                                            <form id="contact-form" action="index.php" method="post">
                                                <input type="hidden" id="to_name" name="to_name"
                                                       value="Samuel Giordano"/>
                                                <input type="hidden" id="to_mail" name="to_mail"
                                                       value="samuelgiordano@live.com"/>
                                                <p>
                                                    <label htmlFor="name">{data.formName}: </label>
                                                    <input type="text" name="name" id="name" className="required"/>
                                                </p>
                                                <p>
                                                    <label htmlFor="email">{data.formEmail}: </label>
                                                    <input type="text" name="email" id="email"
                                                           className="required email"/>
                                                </p>
                                                <p>
                                                    <label htmlFor="subject">{data.formSub}: </label>
                                                    <input type="text" name="subject" id="subject"
                                                           className="required"/>
                                                </p>
                                                <p>
                                                    <label htmlFor="message">{data.formMsg}: </label>
                                                    <textarea name="message" id="message"
                                                              className="required"></textarea>
                                                </p>
                                                <p>
                                                    <img className="ajax-loader" src="/media/bckg/loader_light.gif"
                                                         alt="Sending ..."/>
                                                    <input type="submit" className="btn submit"
                                                           value={data.formSend}/>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a className="rm-close"><span></span></a>
                        </div>
                    </div>
                </div>
            </div>
            <Routes>
                <Route path="/item/:id" element={<PortfolioItem/>}/>
            </Routes>
            <div className="loader"></div>
            <div className="alert"></div>
        </React.Fragment>
    );

}

export default App;