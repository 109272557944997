import React from "react";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import data from "./lang.json";

const PortfolioItem = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    let portSingle = getItemById(id);
    let hasPrevious = id > 1;
    let hasNext = id < data.portSingle.length;
    let outAnimations = document.querySelector('html').getAttribute('data-outAnimation').split(' ');
    let inAnimations = document.querySelector('html').getAttribute('data-inAnimation').split(' ');

    function getItemById(id: number) {
        return data.portSingle.find(element => element.id == id);
    }

    const usePrevious = (value) => {
        const ref = React.useRef();
        React.useEffect(() => { ref.current = value });
        return ref.current;
    }

    const useLocationChange = (action) => {
        const location = useLocation();
        const prevLocation = usePrevious(location);
        React.useEffect(() => {
            action(location, prevLocation)
        }, [location]);
    }

    useLocationChange((location, prevLocation) => {
        console.log('changed from', prevLocation, 'to', location);
        //from home page
        if(!prevLocation){
            document.querySelector('.loader').style.display = 'block';
            document.querySelector('#rm-container').classList.add("rm-in", "rm-nodelay");
            setTimeout(function(){
                onLoad()
            },500);
        }
        if(prevLocation){
            onLoad();
        }
    });

    function onLoad(){
        document.querySelector('.loader').style.display = 'none';
        document.querySelector('html').classList.add('p-overlay-on');
        document.querySelector('.p-overlay').classList.remove('animated');
        outAnimations.forEach((outAnimation) => {
            document.querySelector('.p-overlay').classList.remove(outAnimation);
        });
        inAnimations.forEach((inAnimation) => {
            document.querySelector('.p-overlay').classList.remove(inAnimation);
        });
        document.querySelector('.p-overlay').classList.add('animated');
        inAnimations.forEach((inAnimation) => {
            document.querySelector('.p-overlay').classList.add(inAnimation);
        });
        document.querySelector('.p-overlay').style.display = 'block';
        if(document.querySelector('#rm-container').classList.contains('rm-closed')) {
            document.querySelector('#rm-container').classList.remove('rm-closed');
            document.querySelector('#rm-container').classList.add('rm-open');
        }
    }

    function openPrevious() {
        beforeMove(false, "/item/" + (parseInt(id)-1));
    }

    function openNext() {
        beforeMove(false, "/item/" + (parseInt(id)+1));
    }

    function close() {
        beforeMove(true, "/");
    }

    function beforeMove(isClosing, href){
        if(!isClosing) {
            document.querySelector('.loader').style.display = 'block';
            document.querySelector('#rm-container').classList.add("rm-in", "rm-nodelay");
        }
        scrollTop(document.querySelector('body'), 0);
        if(isClosing) {
            document.querySelector('html').classList.remove('p-overlay-on');
            document.querySelector('#rm-container').classList.remove('rm-in');
        }
        document.querySelector('.p-overlay').classList.remove('animated');
        inAnimations.forEach((inAnimation) => {
            document.querySelector('.p-overlay').classList.remove(inAnimation);
        });
        document.querySelector('.p-overlay').classList.add('animated');
        outAnimations.forEach((outAnimation) => {
            document.querySelector('.p-overlay').classList.add(outAnimation);
        });
        setTimeout(function() {
            document.querySelector('.p-overlay').style.opacity = "0";
            outAnimations.forEach((outAnimation) => {
                document.querySelector('.p-overlay').classList.remove(outAnimation);
            });
            navigate(href);
        } ,500);
    }

    function scrollTop(el, value) {
        if (value === undefined) {
            return el.pageYOffset;
        } else {
            if (el === window || el.nodeType === 9) {
                el.scrollTo(el.pageXOffset, value);
            } else {
                el.pageYOffset = value;
            }
        }
    }

    return (
        <React.Fragment>
            <div className="p-overlay">
                <div className="portfolio-single container">
                    <div className="row">
                        <div className="span8 portfolio-field portfolio-title">
                            <h2>{portSingle.title}</h2>
                        </div>
                        <div className="span4 portfolio-field portfolio-nav">
                            {hasPrevious && <a onClick={openPrevious} className="icon button prev"></a>}
                            <a onClick={close} className="icon button back">{data.close}</a>
                            {hasNext && <a onClick={openNext} className="icon button next"></a>}
                        </div>
                        <div className="span8 portfolio-field">
                            {portSingle.images.map((object, i) =>
                                <img src={`../media/${object}`} alt="project"/>
                            )}
                        </div>
                        <div className="span4 portfolio-field">
                            <h3>{data.desc}</h3>
                            <p>{portSingle.desc}</p>
                            <h3>{data.date}</h3>
                            <p>{portSingle.date}</p>
                            <h3>{data.client}</h3>
                            <p>{portSingle.cli}</p>
                            <h3>{data.tec}</h3>
                            <ul className="tags">
                                {portSingle.tec.map((object, i) =>
                                    <li><a>{object}</a></li>
                                )}
                            </ul>
                            {portSingle.linkPres &&
                                <div className="launch">
                                    <a href={portSingle.linkPres} target="_blank"
                                       className="btn">{data.launchPres}</a>
                                </div>
                            }
                            {portSingle.linkProj &&
                                <div className="launch">
                                    <a href={portSingle.linkProj} target="_blank"
                                       className="btn">{data.launchProj}</a>
                                </div>
                            }
                            {portSingle.linkCode &&
                                <div className="launch">
                                    <a href={portSingle.linkCode} target="_blank"
                                       className="btn">{data.launchCode}</a>
                                </div>
                            }
                        </div>
                        <div className="span12 portfolio-field portfolio-nav bottom">
                            {hasPrevious && <a onClick={openPrevious} className="icon button prev"></a>}
                            <a onClick={close} className="icon button back">{data.close}</a>
                            {hasNext && <a onClick={openNext} className="icon button next"></a>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PortfolioItem;