import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/fonts/font-awesome/brands.scss'
import './style/print.css';
import './style/normalize.css';
import './style/bootstrap.min.css';
import './style/animate.css';
import './style/book.css';
import './style/font-awesome.css';
import './style/jquery.fancybox-1.3.4.css';
import './style/main.css';
import './style/social.scss';
import './style/custom.css';
import './style/skin/cover.css';
import './style/skin/base.css';
import './style/skin/paper-bg.css';
import './style/skin/cover.css';
import './style/skin/body-bg.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
